import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  accordion: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px !important',
    padding: '8px 15px 8px 15px',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.10)',
    '&:before': {
      backgroundColor: 'unset !important'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px 0px 5px'
    }
  },
  accordionExpanIcon: {
    color: '#000000',
    fontSize: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  title: {
    marginTop: 0,
    marginBottom: 14,
    color: 'black',
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 32,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '124%',
      wordWrap: 'break-word'
    },
    '& > * > strong': {
      color: '#158EFF',
      textTransform: 'uppercase'
    }
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    '& > h4': {
      marginTop: 0,
      marginBottom: 24,
      color: '#222222',
      fontSize: 20,
      fontFamily: 'Inter',
      lineHeight: '124%',
      fontWeight: '700'
    },
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      color: '#8D8D8D',
      fontSize: 14,
      fontFamily: 'Inter',
      lineHeight: '124%',
      fontWeight: 400
    },
    '& a': {
      color: '#8D8D8D',
      textDecoration: 'underline',
      fontWeight: 500
    }
  },
  section: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '36px',
    marginBottom: '40px',
    margin: 'auto'
  },
  block: {
    background: 'rgba(245, 245, 247, 0.40)',
    borderRadius: 20,
    padding: '34px 29px 66px 43px',
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: '444px'
  }
}));

const DesktopBlocks = ({ blocks }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      {blocks.map(item => (
        <div key={item.title?.text} className={classes.block}>
          <RichText html={item.block_title?.html} externalClassName={classes.title} />
          <RichText html={item.block_description?.html} externalClassName={classes.description} />
        </div>
      ))}
    </div>
  );
};

DesktopBlocks.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      block_title: PropTypes.shape({
        html: PropTypes.string
      }),
      block_description: PropTypes.shape({
        html: PropTypes.string
      })
    })
  ).isRequired
};

DesktopBlocks.defaultProps = {};

export default DesktopBlocks;

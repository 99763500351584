import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Container from '~components/Container';
import RichText from '~components/RichText';
import RegisterButton from '~containers/landing/RegisterButton';
import { useMediaQuery } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import MobileBlocks from './MobileBlocks';
import DesktopBlocks from './DesktopBlocks';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '116px',
    paddingBottom: '28px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '73px',
      paddingBottom: '40px'
    }
  },
  title: {
    marginTop: 0,
    marginBottom: 64,
    color: 'black',
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 64,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '110%',
      wordWrap: 'break-word',
      [theme.breakpoints.down('xs')]: {
        fontSize: 32
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 25
    }
  },
  description: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
    '& a': {
      textDecoration: 'underline',
      fontWeight: 500
    }
  },
  section: {
    position: 'relative'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}));

const FlexContentBlocks = ({ main_title, blocks }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <RichText html={main_title?.html} externalClassName={classes.title} />
        <Container center maxWidth={isMobile ? 'md' : 'xl'}>
          {isMobile ? <MobileBlocks blocks={blocks} /> : <DesktopBlocks blocks={blocks} />}
          <div className={classes.buttonContainer}>
            <RegisterButton
              size="small"
              variant="blue"
              analyticsTitle={main_title?.text}
              analyticsSection="FAQ"
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

FlexContentBlocks.propTypes = {
  main_title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      block_title: PropTypes.shape({
        html: PropTypes.string
      }),
      block_description: PropTypes.shape({
        html: PropTypes.string
      })
    })
  ).isRequired
};

FlexContentBlocks.defaultProps = {};

export default FlexContentBlocks;

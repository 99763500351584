import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import RichText from '~components/RichText';

const useStyles = makeStyles(() => ({
  accordion: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px !important',
    padding: '0px',
    border: '1px #F1F1F1 solid',
    boxShadow: '0px 2px 0px rgba(0, 0, 0, 0)',
    margin: '0px !important',
    '&:before': {
      backgroundColor: 'unset !important'
    },
    '&:.MuiAccordion-root.Mui-expanded': {}
  },
  accordionExpanIcon: {
    color: '#000000',
    fontSize: 18
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    color: '#222222',
    padding: '12px 0px',
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 18,
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '124%',
      wordWrap: 'break-word'
    },
    '& > * > strong': {
      fontWeight: 600
    }
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 14,
    '& > h4': {
      marginTop: 0,
      marginBottom: 24,
      color: '#222222',
      fontSize: 14,
      fontFamily: 'Inter',
      lineHeight: '124%',
      fontWeight: '700'
    },
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      color: '#8D8D8D',
      fontSize: 14,
      fontFamily: 'Inter',
      lineHeight: '124%',
      fontWeight: 400
    },
    '& a': {
      color: '#8D8D8D',
      textDecoration: 'underline',
      fontWeight: 500
    }
  },
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '9px',
    marginBottom: '40px'
  },
  accordionSummary: {
    '& > .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0px'
    }
  }
}));

const MobileBlocks = ({ blocks }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      {blocks.map((item, index) => (
        <Accordion key={item.title?.text} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.accordionExpanIcon} />}
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
            className={classes.accordionSummary}
          >
            <RichText html={item.block_title?.html} externalClassName={classes.title} />
          </AccordionSummary>
          <AccordionDetails>
            <RichText html={item.block_description?.html} externalClassName={classes.description} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

MobileBlocks.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      block_title: PropTypes.shape({
        html: PropTypes.string
      }),
      block_description: PropTypes.shape({
        html: PropTypes.string
      })
    })
  ).isRequired
};

MobileBlocks.defaultProps = {};

export default MobileBlocks;
